
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_on_rules_representations: { [id: string]: any } = {

    id: "notes_on_rules_representations",
    title: <>Notes on Rules & Representation by Chomsky</>,
    date: "Feb '25",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
My mom got me this book in a nice 80s edition so may as well read it!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
MIND & BODY

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p34 ... vast qualitative difference between the impoverished and unstructured environment, on the one hand, and the highly specific and intrincate structures that uniformly develop ... "the argument from poverty of the stimulus" Socrates' success in eliciting knowledge form a slave boy is a classical example.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p41 ... intrinsic structure is rich (by the argument from poverty of the stimulus) and diverse (by virtue of the apparent in fundamental principles of capacities and mental structures attained)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2 Structures, Capacities, and Conventions

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p65 ... universal grammar is an element of the genotype that maps a course of experience into a particular grammar that constitutes the system of mature knowledge of a language

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p66 ... a system of universal grammar with highly restrctive principles that narrowly constrain the category of attainable grammars, but with parameters that remain open to be fixed by experience

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p70 ... "cognizing" is tacit or implicit knowledge ... cognizing has the structure and character of knowledge, but ... inaccessible to conciousness.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p81 [commenting on Lewis' 'language & languages'] ... there is little reason to suppose that aspects of language that are commonly called "conventional" involve detectable regularities

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p82 ... universal grammar is  part of the genotype  specifying one aspect of the initial state of the human mind & brain, & that the language faculty assumes the character of a particular grammar under the triggering & shaping effect of experience

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p83 Conventions ... , that is regularities in action & belief, ... will cover only some finite & in facte extremely small set of phenomena, again, if we insist that "regularities" have detected probabilities. [this seems to be empirically falsified by LMs?!] There is, for example, no "regularity" that relates to the fact "the candidates wanted each other to win" is paried roghly with the same meaning as "each candidate wanted the otehr to win", whereas "the candidates wanted me to vote for each other" is paired with no meaning. [This claim is undersupported. For example Harris" grammar could give a linguistic explanation of this. Probably a language model could be probed to get probabilistics explanations of this.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p84 A person can neither follow conventions for an infinite language nor have expectations with regard to observance of conventions by others, without some finite characteriation of the language that is somehow under his control.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p85 His problem is to explain how a person can use an infinite language, or have an infinie set of epectations about sound-meaning pairings and much else, without any internal representation of that infinite object, and further, how that infinte object -a language- can be "shared" by a population without any internal representations in the minds of the memebrs of this population. [What is the inferentialist commentary on this?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p87 I see no reasonable alternative to the position that grammars are internally represented in the mind, and that the basic reason why knowledge of language comes to be shared in a suitably idealized population ... is that its members share a rich initial state.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3 Knowledge of Grammar

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p102 we take riding a bicycle to be a skill, whereas knowledge of language ... is not a skill at all. The skill in question might, perhaps, be based on certain reflex systems, in which case it would be incorrect to attribute a cognitive structure in the sense of this discussion.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p103 We should always be on the lookout for new kinds of evidence [for linguistic thoeries], and cannot know in advance what they will be. [not lms though kek]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p115 ... it is a serious mistake to think of knowledge of language as a collection of learned practices to respond & "make utterances£ used for particular ends .... there will be little hope of developing a theory of undertanding unless we study not only capacities to do such-and-such but also the mental structures that enter into the exercise of those capacities

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p116 [generic as example:] "people who watch television suffer brain damage"

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p120 I have argued that the grammar represented in the mind is a "real object" ... the vague everyday notion of language ... should be explained in terms of the real systems represented in the minds of individuals & the similarities among these.

</div>,

<div className="font-mono">
We are now asking wwhether there is som ereason of principle why these grammars must generate recursive sets.

</div>,

<div className="font-mono">
(Putnam, 1961) Some Issues in the Theory of Grammar

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p122 Peters and Ritchie (1973, On the generative power of transformational grammars) showed that if the rules of a transformational grammar meet a certain condition (their "survivor property"), the languages generated will be recursive

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p128 No amount of introspection could tell us that we know, or cognize, or use certain rules or principles of grammar, or that use of language involves mental representations formed by these rules and principles. We have no privileged access to such rules and representations. This conclusion appears to be inconsistent with requirements that have been widely enunciated. Kant, for example, insisted that "All representations have a necessary relation to a possible empirical conciousness. For if they did not have this, and if it were altogether impossible to become concious of them, this would practically amount to the admision of thier non-existence." [truly base to argue "against" kant&wittgenstein by citing descartes across the book]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p134 ... knowledge of grammar, hence language, develops in the child through the interplay of genetically determined principles and a course of experience. ... When the heart, or the visual system, or other organs of the body develop to their mature form, we speak of growth rather than leanring.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
4 Some Elements of Grammar

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p141 ... we should expect to find a fairly rich deductive structure and some open parameters to be set by experience

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p143 When I speak of sentences, I really have in mind their representation in phonetic form. Sentences are composed of words organized into phrases.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p155 surface structure - that is, the actual arrangement of a physical object into phrases - is not a reasonable candidate for semantic interpretation

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p158 do movement rules in fact leave a phonetically unrealized category, as trace theory stipulates?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
5 On the Biological Basis of Language Capacities

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p186 The "only throughly interesting problem" is to determine the range of possibilities that might be realized under given environmental conditions, this range evidently determined by the biological matrix.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p187 What many linguists call "universal grammar" may be regarded as a theory of innate mechanisms, an underlying biological matrix that provides a framework within which the growth of language proceeds.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p199 Restriction of grammatical analysis to a real corpus would be about as sensible as restriction of physics or biology to motion pictures of events happening about us in our normal lives.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p201 compoetence ... system of rules & principles that we assume have ... been internally represented by the person who knows a language ... performance ... the process of production

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p214 ... whether there really are significant analogies between the principles of mental representation & computation that seem to be well motivated in the study of language, and other mental operations, in other domains. Personally, I am rather skeptical; I see no interesting anaogies in other cognitive domains, but so little is known that we can really say very little. [kek]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
6 Language & Unconcious Knowledge

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p219 We may imagine an ideal homogeneous speech community in which there is no variation in style or dialect. [aka some gpus with lms running?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p220 The linguist"s grammar is a scientific thoery, correct insofar as it corresponds to the internally represented grammar.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The language is the set of sentences generated by the grammar. ... The language generated by the grammar is infinite.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p223 The grammar of a language, conceived as a system of rules that weakly generates the sentences of a anguage & strongly generates their structures, has a claim to that "higher degree of reality" that the physicist ascribes to his mathematical models of the universe.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p224 distinguish "grammatical competence" from "pragmatic competence", restricting the first to the knowldege of form and meaning and the second to knowledge of conditions & manner of appropiate use [not the same?!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p225 To the extent that we have an explicit theory of competence, we can attempt to devise performance models to show how this knwoledge is put to use.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p226 In the study of any organisms or machine, we may distinguish between the abstract investigation of the principles by which it operates & the study of the physical realization of the processes & components postulated in the abstract investiagtion. [dualism ?!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p227 Similar problems would arise in the study of an inorganic device that for some reason we would no take apart. [hahahhahha what if?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p230 While it is quite commonly argued that the porpuse of language is communication and that it is pointless to study language apart from its communicative function, there is no formulation of this belief ... from which substantive proposals follow. [i was unsure what this was refering to & asket gpt4o: "Chomsky often argues that language's primary function is not just communication but also the expression of thought. He emphasizes the cognitive aspects of language, suggesting that it serves as a tool for organizing and structuring internal thought processes. This perspective highlights the role of language in facilitating complex reasoning, problem-solving, and the development of abstract ideas, independent of its communicative function."]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p238 Every frog, no doubt, considers his fellows to be a remarkably diverse & interesting crew...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The irregularities, which alone concern us in normal life, _are_ learned.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p239 Structurally, human language is a system with recursive structure-dependent rules, operating on sequences organized in a hierarchy of phrases to generate a countable infinity of sentences.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p247 We use language against a background of shared beliefs about things and within the framework of a system of social institutions. [isn"t language this? (as in literally the shared beliefes etc)]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p248 To gain further understanding of the general nature of the human mind, we should ask in what domains humans seem to develop complex intellectual structures in a more or less uniform way on the basis of restricted data.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

      ]}
    />
  ),
};
