import { VideoBody } from "../components/Entry/VideoBody";

export const video_uncertainty: { [id: string]: any } = {
  id: "video_uncertainty",
  title: <></>,
  date: "",

  Body: (
    <VideoBody url="https://api-dump-v2.s3.eu-west-2.amazonaws.com/movie/67cf6d4ad52f34f3994a7156.mp4" />
  ),
};
