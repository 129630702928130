
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_sociality_of_reason: { [id: string]: any } = {

    id: "notes_sociality_of_reason",
    title: <>Notes on The Sociality of Reason by Terry Pinkard</>,
    date: "25Mar25",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Why the _Phenomenology of Spirit_?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p8 Any form of lide will have certain reasons that it takes as authoritative; to the extent that it becomes self-concious anout these standards & normas, it will develop accounts of why what it _takes_ as authoritative for itself r_really is_ authoritative.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
All these forms of self-conciousness have a mediated (that is, inferential) structure.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p12 ...
</div>,

      ]}
    />
  ),
};
