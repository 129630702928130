
import { EntryBody } from "../components/Entry/EntryBody";

export const brainstorm_after_coling: { [id: string]: any } = {

    id: "brainstorm_after_coling",
    title: <>Brainstorm after coling</>,
    date: "2 0 2 5",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
!&lt;o&gt;! quantified Mcrae norms seems to go well tessler&goodman, also with short utterances in mgen (short so they are "dense" in the lm).

</div>,

<div className="font-mono">
To find sentences for which p-accept+a particular LM have reasonable priors would have to compute a prior of priors (which probably is not uniform, and rather has spikes at 0, 50 and 100 at least) and then compute some statistic to tell which priors are significantly different from this model-prior

</div>,

<div className="font-mono">
then those significantly different maybe some things could be said?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
!&lt;o&gt;! backwards lm, but using weighted forward probs with the gold one-hot in the loss function (and viceversa) this decay could be static or dynamically guided by information

</div>,

<div className="font-mono">
idea to start: get a probabilistic generative grammar and train 2-3 layer transformers on this grammar (forward and backwards) and easily train with many loss functions, also storing all the probs for the dataset would be easy

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
!&lt;o&gt;! distance of subject-object in generic sentences? (who suggested this?!!)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
!&lt;o&gt;! oddballness to measure fractality?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
!&lt;o&gt;! good interpretation of KL divergence (DP-Frost)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
!&lt;o&gt;! the guy doing paraphrase detection (?) with f-1 score of matching averaged scorings of subtrees of the 2 sentences (both ways)

</div>,

      ]}
    />
  ),
};
