import { BiSolidChess } from "react-icons/bi";
import { RiComputerFill } from "react-icons/ri";
import { GiCardJoker } from "react-icons/gi";
import { IoMdPlanet } from "react-icons/io";
import { IoInfinite } from "react-icons/io5";
import { GiMeshBall } from "react-icons/gi";
import { GiSkeletonKey } from "react-icons/gi";
import { LiaHeartSolid } from "react-icons/lia";
import { PiChartScatterBold } from "react-icons/pi";
import { IoLanguage } from "react-icons/io5";

import { Button, Grid } from "@mantine/core";
import { VideoBody } from "../../components/Entry/VideoBody";
import { TwoIconButton } from "../../components/Buttons/TwoIconButton";
import { GiEvilLove } from "react-icons/gi";
import { PiUsersFourFill, PiMagicWandFill } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { GiPerspectiveDiceSixFacesFive } from "react-icons/gi";
import { GiWorld } from "react-icons/gi";
import { HiChip } from "react-icons/hi";
import { GiSnakeSpiral } from "react-icons/gi";

import { TbWritingSign } from "react-icons/tb";
import { FaTrainTram, FaUserSecret, FaFilm } from "react-icons/fa6";
import { LuBird } from "react-icons/lu";
import { MdMerge } from "react-icons/md";


export const Cyberport = () => {
  return (
    <div className="flex flex-col items-center w-full h-full  p-5 pr-2 pl-2 sm:pr-5 sm:pl-5 space-y-1 font-mono">
      <div className="flex flex-col w-2/3 space-y-5 items-center text-justify">
        <p>
          It is problematic that the web is only accessible through a few
          corporate portals.
        </p>
        <p>The web should be a web.</p>
        <p>
          Here there are cyberspace-to-cyberspace connections to different
          places.
        </p>
        <div className="text-center mt-5">* * *</div>
        <Grid className="" gutter={{ base: 20, xs: "md", md: "xl", xl: 50 }}>
        <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<FaFilm />}
              iconRight={<PiMagicWandFill />}
              link="https://obobo.net"
              cybertitle="obobo.net"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<GiCardJoker />}
              iconRight={<IoMdPlanet />}
              link="https://planetomegatcg.com/"
              cybertitle="Planet Omega TCG"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<IoInfinite />}
              iconRight={<GiMeshBall />}
              link="https://infinitemesh.co/"
              cybertitle="Infinite Mesh"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<GiSkeletonKey />}
              iconRight={<LiaHeartSolid />}
              link="https://domenecmirallestagliabue.com/"
              cybertitle="Domenec!"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<GiSnakeSpiral />}
              iconRight={<TbWritingSign />}
              link="https://elvisirdeabisinia.blogspot.com/"
              cybertitle="El Visir de Abisinia"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<BiSolidChess />}
              iconRight={<RiComputerFill />}
              link="https://maxim-saplin.github.io/llm_chess/"
              cybertitle="LLM Chess"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<PiChartScatterBold />}
              iconRight={<IoLanguage />}
              link="https://genericlanguage.com/"
              cybertitle="Generic Language"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<GiEvilLove />}
              iconRight={<PiUsersFourFill />}
              link="https://majindonpatch.com/"
              cybertitle="MajinDonPatch"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<FaTrainTram />}
              iconRight={<LuBird />}
              link="https://jac-dietario.blogspot.com/"
              cybertitle="Vetanilla de Vagón"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<RxCross2 />}
              iconRight={<GiPerspectiveDiceSixFacesFive />}
              link="https://ponzi.cat/"
              cybertitle="Pol!"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<GiWorld />}
              iconRight={<HiChip />}
              link="https://nousresearch.com/"
              cybertitle="Nous Research"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TwoIconButton
              iconLeft={<FaUserSecret />}
              iconRight={<MdMerge />}
              link="https://docs.google.com/document/d/1OjbjU5AOz4Ftn9xHQrX3oFQGhQ6RDUuXQipnQ9gn6tU/edit?tab=t.0"
              cybertitle="Rombodawg Secret Sauce"
            />
          </Grid.Col>
        </Grid>

        <div className="text-center mt-5">* * *</div>
        {/* <div className="flex flex-col items-center"> */}
        <div className="w-72 border">
          <VideoBody url="https://gus-public.s3.us-east-1.amazonaws.com/endlessjournal/beetle_book.mp4" />
        </div>

        {/* </div> */}
      </div>
    </div>
  );
};
