
import { EntryBody } from "../components/Entry/EntryBody";

export const generics_in_the_fractal_structure_of_language: { [id: string]: any } = {

    id: "generics_in_the_fractal_structure_of_language",
    title: <>Generics in the Fractal Structure of Uncertainty in Language</>,
    date: "28 feb 25",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Uncertainty in language has a fractal structure. The uncertainty contours of language are self-similar at different scales, for example: word, sentence, paragraph, chapter, book.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Intuitively language is a fractal because we can _describe_ our experience ar varying scales and levels of detail.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Generics are local maximums of entropy and local minimums of surprisal. Quantified sentences are local maximums of entropy but have average surprisal.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
At paragraph-ish level, generics (as a sentence) thus seem to be self-similar wrt uncertainty/information.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
How does this link with Davidson"s ideas of radical interpretability? The motor of language is uncertainty (as that is what allows for interpretation), but for uncertainty to be a thing, there have to be patterns across langauge. Generic sentences could be one of those patterns, a way to express certain things that have particular information-bearing properties, thus enabling interpretation.

</div>,

      ]}
    />
  ),
};
